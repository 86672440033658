/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Area.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { Geometry, Point }    from 'geojson';
import { AreaType }           from './AreaType';

interface IArea {
  id: number,
  type_id: number,
  code: string,
  geolocation: Point | null,
  boundary: Geometry | null,
  land_area: number | null,

  name_en: string | null,
  name_fr: string | null,

  type: AreaType,
}

class AreaModel extends Model<IArea> {
  _slug = 'area';

  basePath = '/v1/demographics/areas';

  attributesTypes: { [attr in keyof IArea]?: (sourceAttr: any) => IArea[attr] } = {};

  key: keyof IArea = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IArea | string)[] } = {
    create: [],
    save  : [],
  };
}

export class Area extends AreaModel implements IArea {
  id!: number;

  type_id!: number;

  code!: string;

  geolocation!: Point | null;

  type!: AreaType;

  boundary!: Geometry | null;

  land_area!: number | null;

  name_en!: string | null;

  name_fr!: string | null;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}

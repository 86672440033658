/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - formatNumber.ts
 */

import { getI18n } from 'react-i18next';

const abbrLookupTable = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'Q' },
];

export interface FormatNumberFnOptions {
  /**
   * Two letter string for string localization. eg. `en`|`fr`
   */
  locale: string,

  /**
   * Set true if number should be formatted as a monetary value
   */
  currency: boolean,

  /**
   * How many decimals should be included
   */
  decimals: number,

  /**
   * If true, big numbers will be replaced with their abbreviated counterpart. eg. `1000 -> 1k`
   *
   * The prefix used depends on the given locale
   */
  abbreviated: boolean

  formatter: Intl.NumberFormat
}

export type FormatNumberFn = (number: number, options?: Partial<FormatNumberFnOptions>) => string

const formatNumber: FormatNumberFn = (number, options = {}) => {
  const { abbreviated = false } = options;

  let num = number;
  let item;

  if (abbreviated) {
    item = abbrLookupTable.slice().reverse().find(function (item) {
      return number >= item.value;
    });

    num = item ? num / item.value : num;
  }

  const formatter = options.formatter ?? getNumberFormatter(options);

  const formattedNumber = formatter.format(num);

  return formattedNumber +
    (abbreviated && (item && item.symbol.length > 0) ? getI18n().t(`common:numbers-abbr.${ item.symbol }`) : '');
};

/**
 * Generates a number formatter compatible with the `formatNumber()` `formatter
 * @param options
 */
function getNumberFormatter(options: Partial<Omit<FormatNumberFnOptions, 'formatter' | 'abbreviated'>> = {}) {
  const { locale = getI18n().language, currency = false, decimals = 0 } = options;

  return new Intl.NumberFormat(`${ locale }-ca`, {
    style                : currency ? 'currency' : 'decimal',
    currency             : 'CAD',
    currencyDisplay      : 'narrowSymbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  });
}

export { formatNumber, getNumberFormatter };

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - SaleType.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { SaleTypeEnum }       from './enums/SaleTypeEnum';

export interface ISaleType {
  id: number,
  slug: SaleTypeEnum,
}

class SaleTypeModel extends Model<ISaleType> {
  _slug = 'sale-type';

  basePath = '/v1/sale-types';

  attributesTypes: { [attr in keyof ISaleType]?: (sourceAttr: any) => ISaleType[attr] } = {};

  key: keyof ISaleType = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ISaleType | string)[] } = {
    create: [],
    save  : [],
  };
}


export class SaleType extends SaleTypeModel implements ISaleType {
  id!: number;

  slug!: SaleTypeEnum;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  isBonusSale() {
    return this.slug === SaleTypeEnum.Bonus || this.slug === SaleTypeEnum.BUA;
  }
}

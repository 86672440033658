/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - PricelistPeriodType.ts
 */

export enum PricelistPeriodType {
  Always       = 'always',
  Specific     = 'specific',
  FiscalMonths = 'fiscal-months',
}

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Providers.tsx
 */

import React                  from 'react';
import { HTML5Backend }       from 'react-dnd-html5-backend';
import QueryClientProvider    from 'providers/QueryClientProvider/QueryClientProvider';
import { ErrorBoundary }      from 'components/ErrorBoundary';
import TranslationProvider    from './TranslationProvider';
import { DndProvider }        from 'react-dnd';
import MaintenanceProvider    from './MaintenanceProvider/MaintenanceProvider';
import GuestsAccessProvider   from './GuestsAccessProvider/GuestsAccessProvider';
import AuthProvider           from './AuthProvider';
import { ParametersProvider } from './ParametersProvider';
import ModulesProvider        from './ModulesProvider';
import ScopeProvider          from './ScopeProvider';
import HeadlinesProvider      from './HeadlinesProvider';
import { BreadcrumbProvider } from './BreadcrumbProvider';
import * as Tooltip           from '@radix-ui/react-tooltip';
import ContractsContext       from '../scenes/Contracts/ContractsContext';

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider>
      <Tooltip.Provider delayDuration={ 250 } disableHoverableContent>
        <ErrorBoundary>
          <TranslationProvider>
            <React.Suspense fallback={ <div>Loading...</div> }>
              <DndProvider backend={ HTML5Backend }>
                <MaintenanceProvider>
                  <GuestsAccessProvider>
                    <AuthProvider>
                      <ParametersProvider>
                        <ModulesProvider>
                          <ScopeProvider>
                            <HeadlinesProvider>
                              <BreadcrumbProvider>
                                <ContractsContext>
                                  { children }
                                </ContractsContext>
                              </BreadcrumbProvider>
                            </HeadlinesProvider>
                          </ScopeProvider>
                        </ModulesProvider>
                      </ParametersProvider>
                    </AuthProvider>
                  </GuestsAccessProvider>
                </MaintenanceProvider>
              </DndProvider>
            </React.Suspense>
          </TranslationProvider>
        </ErrorBoundary>
      </Tooltip.Provider>
    </QueryClientProvider>
  );
};

export default Providers;

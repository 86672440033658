/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Collection.ts
 */

import { getProperty } from 'dot-prop';

// -------------------
// Helpers

type ComparisonOperator = '==' | '!=' | '===' | '!==' | '<' | '<=' | '>=' | '>' | 'in' | 'not in' | 'includes'

type Attribute<T> = keyof T | string;

export type ValueSelector<T> = Attribute<T> | ((item: T) => any) | null

export function getItemValue<T>(item: T, selector: ValueSelector<T>, allowCollectionDig: boolean = false) {
  if (selector === null) {
    return item;
  }

  if (typeof selector === 'function') {
    return selector(item);
  }

  if (item instanceof Collection && allowCollectionDig) {
    // @ts-ignore
    return getProperty(item.first(), selector);
  }

  // @ts-ignore
  return getProperty(item, selector);
}

function defaultCompare(a: any, b: any) {
  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

/**
 * @param a {string}
 * @param b {string}
 * @returns {number}
 */
function stringCompare(a: string, b: string) {
  return a?.localeCompare(b);
}

// noinspection EqualityComparisonWithCoercionJS
const comparisonMethods: { [operator in ComparisonOperator]: (a: any, b: any) => boolean } = {
  '<' : (a: string, b: string) => a < b,
  '<=': (a: string, b: string) => a <= b,
  // eslint-disable-next-line
  '==' : (a: string, b: string) => a == b,
  '===': (a: string, b: string) => a === b,
  '!==': (a: string, b: string) => a !== b,
  // eslint-disable-next-line
  '!='      : (a: string, b: string) => a != b,
  '>='      : (a: string, b: string) => a >= b,
  '>'       : (a: string, b: string) => a > b,
  'in'      : (a: any, b: any[]) => b.includes(a),
  'not in'  : (a: any, b: any[]) => !b.includes(a),
  'includes': (a: any[], b: any) => a.includes(b),
};

function normalize(s: string) {
  return s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * @param t
 * @param selectors {string[]}
 * @param request {string | RegExp}
 * @returns {boolean}
 */
function multiSearch(t: any, selectors: string[], request: string | RegExp) {
  for (const selector of selectors) {
    const value = getItemValue(t, selector);

    // Ignore null and undefined values
    if (value === null || value === undefined) {
      continue;
    }

    const i = normalize(value).search(request);
    if (i !== -1) {
      return true;
    }
  }

  return false;
}

/**
 * @template T
 */
export default class Collection<T> extends Array<T> {
  static from<T>(arrayLike: ArrayLike<T>): Collection<T>;
  static from<T, U>(arrayLike: ArrayLike<T>, mapfn?: (v: T, k: number) => U, thisArg?: any): Collection<U>;
  static from<T, U>(arrayLike: ArrayLike<T>, mapfn?: (v: T, k: number) => U, thisArg?: any): any {
    if (mapfn) {
      return super.from(arrayLike, mapfn, thisArg) as Collection<U>;
    }

    return super.from(arrayLike) as Collection<T>;
  }

  /**
   *
   * @param args
   * @return Collection<T>
   */
  static make = <T>(...args: T[]): Collection<T> => Collection.from(args) as Collection<T>;

  static ofType<T, U>(type: { new(t: U): T }): { make: (args: U[]) => Collection<T> } {
    return {
      make: (args: U[]) => {
        if (!args) {
          return new Collection();
        }

        return Collection.from(args, (t) => new type(t)) as Collection<T>;
      },
    };
  }

  first(): T | null {
    if (this.length > 0) {
      return this[0];
    }

    return null;
  }

  last(): T | null {
    if (this.length > 0) {
      return this[this.length - 1];
    }

    return null;
  }

  at(index: number): T | undefined {
    if (index < 0) {
      return this[this.length + index];
    }

    return this[index];
  }

  /** Override for typescript
   *  @see Array.map
   */
  // @ts-ignore
  map<U>(callbackfn: (value: T, index: number, array: T[]) => U, thisArg?: any): Collection<U> {
    return super.map(callbackfn, thisArg) as Collection<U>;
  }

  flat<A>(depth?: number): Collection<A> {
    // @ts-ignore
    return super.flat(depth) as Collection<A>;
  }

  // @ts-ignore
  flatMap<U, This = undefined>(
    callback: (this: This, value: T, index: number, array: T[]) => (Readonly<Collection<U>> | Readonly<U[]> | U),
    thisArg?: This,
  ): Collection<U> {
    return super.flatMap(callback as (this: This, value: T, index: number, array: T[]) => (ReadonlyArray<U> | U),
      thisArg,
    ) as Collection<U>;
  }

  /**
   * Creates a shallow copy of the array and reverse it
   * @inheritDoc
   */
  reverse(): Collection<T> {
    return Collection.from([ ...this ].reverse());
  }

  /**
   * @see Array.filter
   * @param predicate If omited, each value will be casted to boolean for filtering. Useful to filter out null values, but can give unwanted result if a zero or empty array should be allowed.
   * @param thisArg
   */
  // @ts-ignore
  filter<S extends T>(predicate: (value: T, index: number, array: T[]) => boolean, thisArg?: any): Collection<S> {
    return super.filter(predicate ?? (t => !!t), thisArg) as Collection<S>;
  }

  /**
   * Filter out any entry in the collection that is either null or undefined
   */
  // @ts-ignore
  filterNull<S extends Exclude<T, null | undefined>>(): Collection<S> {
    return super.filter((t: T): t is S => t !== null && t !== undefined) as Collection<S>;
  }

  // Sorting

  /**
   * @param selector
   * @param asc
   * @return {Collection<T>}
   */
  sortBy(selector: ValueSelector<T>, asc: boolean = true): Collection<T> {
    if (this.length === 0) {
      return Collection.from(this) as Collection<T>;
    }

    // Define our comparison technique
    const comp = typeof getItemValue(this[0], selector) === 'string' ? stringCompare : defaultCompare;

    // Array.sort() is mutable, we want to be immutable
    const newCol = Collection.from(this);
    newCol.sort((a, b) => comp(getItemValue(a, selector), getItemValue(b, selector)));

    if (!asc) {
      return newCol.reverse();
    }

    return newCol as Collection<T>;
  }

  // Filtering
  /**
   *
   * @return {Collection<T>}
   * @param selector
   * @param operator
   * @param value
   */
  filterBy(selector: ValueSelector<T>, operator: ComparisonOperator, value: any): Collection<T> {
    // Set our comparison function
    const comp = comparisonMethods[operator];

    // Array.filter() is immutable
    return Collection.from(this.filter((t) => comp(getItemValue(t, selector), value))) as Collection<T>;
  }

  /**
   * The splice function removes all the matching elements from the collection, and return them, alongside a copy of the list without the extracted elements.
   * Leaving out the value parameter will make the matching work the same as the `Collection.filterBy` method
   * @param selector
   * @param value
   * @param operator
   */
  spliceBy(
    selector: ValueSelector<T>,
    value?: any,
    operator: ComparisonOperator = '===',
  ): [ spliced: Collection<T>, collection: Collection<T> ] {
    const spliced    = new Collection<T>();
    const collection = new Collection<T>();

    const comparator = comparisonMethods[operator];

    for (const t of this) {
      const match = comparator(getItemValue(t, selector), value === undefined ? true : value);
      if (match) {
        spliced.push(t);
      } else {
        collection.push(t);
      }
    }

    return [ spliced, collection ];
  }

  // Filtering
  /**
   * @param selector
   * @param value
   * @return {T|null}
   */
  findBy(selector: ValueSelector<T>, value: any): T | undefined {
    return this.find((t) => getItemValue(t, selector) === value);
  }

  // Searching
  /**
   *
   * @param request
   * @param selectors
   * @return {Collection<T>}
   */
  search(request: string | RegExp, selectors: ValueSelector<T> | ValueSelector<T>[]): Collection<T> {
    // Return an untouched copy on empty search
    if (typeof request === 'string') {
      if (request.length === 0) {
        return Collection.from(this) as Collection<T>;
      }

      request = normalize(request);
    }

    if (!Array.isArray(selectors)) {
      selectors = [ selectors ];
    }

    return Collection.from(this.filter((t) => multiSearch(t, selectors as string[], request))) as Collection<T>;
  }

  // Others

  /**
   * @param {string} attr
   * @return {Collection<unknown>}
   */
  pluck<U extends keyof T | string, S = U extends keyof T ? T[U] : unknown>(attr: U): Collection<S> {
    return this.map(t => getProperty<T, string>(t, attr as string) as S);
  }

  /**
   * Replace an existing element in the collection by the given one, using the given valueSelector for matching.
   * If no element in the collection matches, nothing is done.
   * @param newEl
   * @param attr
   * @param oldAttrValue
   * @return {Collection<T>}
   */
  replace(newEl: T, attr: ValueSelector<T> = 'id', oldAttrValue: any = null): Collection<T> {
    const ref = oldAttrValue ?? getItemValue(newEl, attr);

    // Get the index of the element to replace
    const index = this.findIndex(t => getItemValue(t, attr) === ref);

    // Start by copying the collection without the element matching the attribute
    const col = index === -1 ? Collection.from(this) : this.filterBy(attr, '!==', oldAttrValue ?? getItemValue(newEl, attr));

    if (newEl !== null) {
      // Add the new element
      if (index !== -1) {
        col.splice(index, 0, newEl);
      } else {
        col.push(newEl);
      }
    }

    return col;
  }

  sum(selector?: ValueSelector<T>): number {
    return this.reduce((acc, t) => {
      if (!selector) {
        // If the item is not a number, skip it
        return typeof t === 'number' ? acc + t : acc;
      }

      const item = getItemValue(t, selector);
      return typeof item === 'number' ? acc + item : acc;
    }, 0);
  }

  min(selector: ValueSelector<T> = t => t): T | null {
    return this.sortBy(selector).first();
  }

  max(selector: ValueSelector<T> = t => t): T | null {
    return this.sortBy(selector).last();
  }

  /**
   * @see https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d#gistcomment-3646957
   *
   * @return {*}
   * @param selector
   * @param asObject
   */
  groupBy(selector: ValueSelector<T>, asObject?: false): Collection<Collection<T>>;
  groupBy(selector: ValueSelector<T>, asObject?: true): Record<string, Collection<T>>;
  groupBy(selector: ValueSelector<T>, asObject: boolean = false): Record<string, Collection<T>> | Collection<Collection<T>> {
    const groups = this.reduce((results: Record<string, Collection<T>>, item: T) => {
        // Get the first instance of the key by which we're grouping
        let group = getItemValue(item, selector);

        // Ensure that there's an array to hold our results for this group
        results[group] = results[group] || new Collection();

        // Add this item to the appropriate group within results
        results[group].push(item);

        // Return the updated results object to be passed into next reduce call
        return results;
      },

      // Initial value of the results object
      {},
    );

    return asObject ? groups : Collection.from(Object.values(groups));
  };

  keyedBy(selector: ValueSelector<T>, allowCollectionDig: boolean = true): { [key: string]: T } {
    return Object.fromEntries(this.map(t => ([ getItemValue(t, selector, allowCollectionDig), t ])));
  }

  unique() {
    const flags: any[] = [];

    return this.filter(t => {
      // If the key is already in our flag, we ignore the item
      if (flags.includes(t)) {
        return false;
      }

      // The item is the first with this key. Save the key and keep it
      flags.push(t);
      return true;
    });
  }

  uniqueBy(attr: ValueSelector<T>): Collection<T> {
    const flags: any[] = [];

    return this.filter(t => {
      // get the key
      const key = getItemValue(t, attr);

      // If the key is already in our flag, we ignore the item
      if (flags.includes(key)) {
        return false;
      }

      // The item is the first with this key. Save the key and keep it
      flags.push(key);
      return true;
    });
  }

  /**
   * Swaps two elements in the collection using the given attribute and values
   * @param attr
   * @param firstItemAttrValue
   * @param secondItemAttrValue
   */
  swapBy(attr: ValueSelector<T>, firstItemAttrValue: any, secondItemAttrValue: any): Collection<T> {
    const firstIndex  = this.findIndex((t) => getItemValue(t, attr) === firstItemAttrValue);
    const secondIndex = this.findIndex((t) => getItemValue(t, attr) === secondItemAttrValue);

    return this.swap(firstIndex, secondIndex);
  }

  /**
   * Swaps two elements in the collection using their index
   * @param firstItemIndex
   * @param secondItemIndex
   */
  swap(firstItemIndex: number, secondItemIndex: number): Collection<T> {
    const newColl                                         = Collection.from(this);
    [ newColl[secondItemIndex], newColl[firstItemIndex] ] = [ newColl[firstItemIndex], newColl[secondItemIndex] ];

    return newColl;
  }

  /**
   * Return a new collection with the element added at the end.
   * This method does not mutate the original collection
   * @param {T[]} items
   */
  append(...items: T[]): Collection<T> {
    return Collection.make(...this, ...items);
  }

  /**
   * Return a copy of the collection as an array.
   * Nested items are left intact
   */
  toArray() {
    return [ ...this ];
  }

  /**
   * Validates at least one item in the collection pass the given test
   * @param selector
   * @param operator
   * @param value
   */
  has(selector: ValueSelector<T>, operator: ComparisonOperator, value: any): boolean {
    // Set our comparison function
    const comp = comparisonMethods[operator];

    // Array.filter() is immutable
    return this.filter((t) => comp(getItemValue(t, selector), value)).length > 0;
  }

  /**
   * Split the collection in chunks of the given size
   * @param chunkSize a Collection of Collection of the given chunk size
   */
  chunk(chunkSize: number): Collection<Collection<T>> {
    if (chunkSize === 0) {
      return Collection.from([ this ]);
    }

    const chunkedCollection = new Collection<Collection<T>>();

    for (let i = 0; i < this.length; i += chunkSize) {
      chunkedCollection.push(Collection.from(this.slice(i, i + chunkSize)));
    }

    return chunkedCollection;
  }

  /**
   * Return a random element of the collection
   */
  getRandomElement() {
    return this[Math.floor(Math.random() * this.length)];
  }
}

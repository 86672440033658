/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - dateTime.ts
 */

import { DateTime, Interval } from 'luxon';

export type DateString = `${ number }-${ number }-${ number }`
export const dateFormatString = `yyyy-MM-dd`;

/**
 * Parse ISO date to DateTime forcing UTC
 * @param date
 */
export function parseDate(date: string) {
  return DateTime.fromISO(date, { zone: 'utc' }).startOf('day');
}

/**
 * Format DateTime to Date `yyyy-mm-dd`
 * @param date
 */
export function formatDate(date: DateTime) {
  return date.toFormat(dateFormatString) as DateString;
}

/**
 * Parse `HH:mm:ss` time to DateTime forcing UTC
 * @param time
 */
export function parseTime(time: string) {
  return DateTime.fromFormat(time, 'HH:mm:ss', { zone: 'utc' });
}

/**
 * Format DateTime to time `HH:mm`
 * @param time
 */
export function formatTime(time: DateTime) {
  return time.toISOTime({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });
}

/**
 * Format DateTime to time with seconds `HH:mm:ss`
 * @param time
 */
export function formatTimeWithSeconds(time: DateTime) {
  return time.toISOTime({ suppressMilliseconds: true, includeOffset: false });
}

export const getWeekStart = (week: number, year: number | null = null) => {
  year = year ?? DateTime.now().year;

  const yearStart = DateTime.fromObject({ year }).startOf('year').startOf('week');

  return yearStart.plus({ week: week - 1 });
};

/**
 * Take two dates, and return all the days in between , including the given start and end date
 */
export function getDaysBetween(start: DateTime, end: DateTime) {
  return Interval.fromDateTimes(start, end.plus({ day: 1 }))
                 .splitBy({ days: 1 })
                 .map(interval => interval.start.toISODate() as DateString);
}
/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - QueryClientProvider.tsx
 */

import React                                 from 'react';
import { QueryClientProvider as QCProvider } from '@tanstack/react-query';
import { queryClient }                       from 'library/Model/QueryClient';
import { ReactQueryDevtools }                from '@tanstack/react-query-devtools';

interface QueryClientProviderProps {
  children: React.ReactNode;
}

const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  return (
    <QCProvider client={ queryClient }>
      { children }
      <ReactQueryDevtools initialIsOpen={ false } buttonPosition="bottom-left"/>
    </QCProvider>
  );
};

export default QueryClientProvider;

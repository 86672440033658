/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - CensusSubdivision.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { MultiPolygon }       from 'geojson';

export interface ICensusSubdivision {
  id: number,
  census: number,
  name: string,
  type: string,
  province: string,
  landarea_sqkm: number,
  dissemination_uid: string,
  geometry: MultiPolygon,
}

class CensusSubdivisionModel extends Model<ICensusSubdivision> {
  _slug = 'census-subdivision';

  basePath = '/v1/census-subdivisions';

  attributesTypes: { [attr in keyof ICensusSubdivision]?: (sourceAttr: any) => ICensusSubdivision[attr] } = {};

  key: keyof ICensusSubdivision = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICensusSubdivision)[] } = {
    create: [],
    save  : [],
  };
}

export class CensusSubdivision extends CensusSubdivisionModel implements ICensusSubdivision {
  census!: number;

  geometry!: MultiPolygon;

  id!: number;

  name!: string;

  province!: string;

  type!: string;

  dissemination_uid!: string;

  landarea_sqkm!: number;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getName() {
    return this.name;
  }
}

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - CensusFederalElectoralDistrict.ts
 */

import { Model }              from 'library/Model';
import { ModelPersistAction } from 'library/Model/types';
import { MultiPolygon }       from 'geojson';
import { I18n }               from '../providers/TranslationProvider/i18n';
import { LocalizedName }      from 'library/types';

export interface ICensusFederalElectoralDistrict {
  id: number,
  census: number,
  name_en: string,
  name_fr: string,
  province: string,
  landarea_sqkm: number,
  dissemination_uid: string,
  geometry: MultiPolygon,
}

class CensusFederalElectoralDistrictModel extends Model<ICensusFederalElectoralDistrict> {
  _slug = 'census-federal-electoral-district';

  basePath = '/v1/census-federal-electoral-districts';

  attributesTypes: { [attr in keyof ICensusFederalElectoralDistrict]?: (sourceAttr: any) => ICensusFederalElectoralDistrict[attr] } = {};

  key: keyof ICensusFederalElectoralDistrict = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof ICensusFederalElectoralDistrict)[] } = {
    create: [],
    save  : [],
  };
}

export class CensusFederalElectoralDistrict extends CensusFederalElectoralDistrictModel implements ICensusFederalElectoralDistrict {
  census!: number;

  geometry!: MultiPolygon;

  id!: number;

  province!: string;

  dissemination_uid!: string;

  landarea_sqkm!: number;

  name_en!: string;

  name_fr!: string;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }

  getName(i18n: I18n) {
    return this[`name_${ i18n.language }` as LocalizedName];
  }
}

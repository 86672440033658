/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - prices.ts
 */

import { PriceType }          from '../../models/enums/PriceType';
import { getNumberFormatter } from './formatNumber';
import i18n, { TFunction }    from 'i18next';
import Collection             from '../Collection';

/**
 * Make a price human readable
 * @param price
 * @param t
 */
export function formatPrice(price: { pricing: PriceType, value: number }, t: TFunction) {
  const formatter: Intl.NumberFormat = getNumberFormatter({ currency: true, decimals: 6 });

  const value = formatter.format(price.value);

  switch (price.pricing) {
    case PriceType.unit:
      return `${ value }/${ t('common:units.week').toLowerCase() }/face`;
    case PriceType.unitProduct:
      return `${ value }/${ t('common:units.week').toLowerCase() }`;
    case PriceType.cpm:
      return `${ value } cpm`;
    case PriceType.perOccasion:
      return `${ value }/occ`;
  }
}

/**
 * Make a price range human-readable
 * @param price
 * @param t
 */
export function formatPriceRange(price: { pricing: PriceType, min: number, max: number }, t: TFunction) {
  const formatter: Intl.NumberFormat = getNumberFormatter({ currency: true, decimals: 6 });

  const min = formatter.format(price.min);
  const max = formatter.format(price.max);

  switch (price.pricing) {
    case PriceType.unit:
      return `${ min }-${ max }/${ t('common:units.week') }/face`;
    case PriceType.unitProduct:
      return `${ min }-${ max }/${ t('common:units.week') }`;
    case PriceType.cpm:
      return `${ min }-${ max } cpm`;
    case PriceType.perOccasion:
      return `${ min }-${ max }/occ`;
  }
}

/**
 * Takes a list of prices and format it in the best way possible
 * @param prices
 * @param t
 */
export function formatPrices(prices: { pricing: PriceType, value: number }[], t: TFunction) {
  if (prices.length === 0) {
    return '';
  }

  // Are all prices equal ?
  if (prices.every(p => p.pricing === prices[0].pricing)) {
    // List all the different values

    const priceValues = Collection.from(Array.from(new Set(prices.map(p => p.value)).values()));

    if (priceValues.length === 1) {
      return formatPrice(prices[0], i18n.t);
    }

    const minPrice = priceValues.min() ?? 0;
    const maxPrice = priceValues.max() ?? 0;

    return formatPriceRange({ pricing: prices[0].pricing, min: minPrice, max: maxPrice }, t);
  }

  return t('campaign-planner:prices.multiple-prices');
}
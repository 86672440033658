/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - PriceType.ts
 */

export enum PriceType {
  unit        = 'unit',
  unitProduct = 'unit-product',
  cpm         = 'cpm',
  perOccasion = 'per-occasion',
}

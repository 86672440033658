/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - SaleTypeEnum.ts
 */

export enum SaleTypeEnum {
  Guaranteed     = 'guaranteed',
  Paced          = 'paced',
  PacedTargerted = 'paced-targeted',
  Bonus          = 'bonus',
  BUA            = 'bua'
}

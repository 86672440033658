/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Product.ts
 */

import i18n                                                       from 'i18next';
import Collection                                                 from 'library/Collection';
import { DateTime }                                               from 'luxon';
import {
  Format,
  ILocation,
  InventoryPicture,
  InventoryResourceRestriction,
  Location,
  LoopConfiguration,
  OpeningHours,
  Pricelist,
  ProductCategory,
  Property,
  PropertyType,
  ScreenType,
  Unavailability,
}                                                                 from 'models';
import { Model }                                                  from 'library/Model';
import { ModelPersistAction }                                     from 'library/Model/types';
import { LocalizedKey }                                           from 'library/types';
import { ProductAttachment }                                      from './Attachment';
import { ProductImpressionsModel }                                from './ImpressionsModel';
import { makeRequest }                                            from 'library/Request/Request';
import { preparePathForSingleModelAction, preparePathParameters } from 'library/Model/utils';
import { makeRoute }                                              from 'library/modelsUtils';
import { HTTPMethod }                                             from 'library/Request';
import { InventoryResource }                                      from './InventoryResource';
import { MediaType }                                              from './enums/MediaType';
import { InventoryResourceModel }                                 from './interfaces/InventoryResourceModel';
import { SaleTypeEnum }                                           from './enums/SaleTypeEnum';
import ProductType                                                from './enums/ProductType';

interface ProductWarnings {
  missing_locations: boolean;
}

export interface IProduct {
  allowed_media_types: MediaType[],
  allowed_media_types_inherited: boolean,
  allows_audio: boolean | null,
  allows_audio_inherited: boolean,
  allows_motion: boolean | null,
  allows_motion_inherited: boolean,
  attachments: Collection<ProductAttachment>,
  category: ProductCategory,
  category_id: number,
  cover_picture: InventoryPicture | null,
  cover_picture_id: number | null,
  created_at: DateTime,
  format: Format,
  format_id: number | null,
  format_id_inherited: boolean,
  id: number,
  impressions_models: Collection<ProductImpressionsModel>,
  inventory_resource: InventoryResource,
  inventory_resource_id: number,
  is_sellable: boolean,
  linked_product_id: boolean,
  locations: Collection<Location>,
  loop_configurations: Collection<LoopConfiguration>;
  mockups_count: number,
  name_en: string,
  name_fr: string,
  notes: string,
  opening_hours: Collection<OpeningHours>,
  pictures: Collection<InventoryPicture>,
  pictures_count: number,
  pricelist: Pricelist | null,
  primary_broadcast_location_id: number | null,
  production_cost: number | null,
  production_cost_inherited: boolean,
  programmatic_price: number | null,
  programmatic_price_inherited: boolean,
  property: Property,
  property_id: number,
  quantity: number,
  restrictions: Collection<InventoryResourceRestriction>;
  sale_types_slugs: SaleTypeEnum[],
  sale_types_slugs_inherited: boolean,
  screen_size_in: number | null,
  screen_size_in_inherited: boolean,
  screen_type: ScreenType | null,
  screen_type_id: number | null,
  screen_type_id_inherited: boolean,
  site_type: PropertyType | null,
  site_type_id: number | null,
  site_type_id_inherited: boolean,
  type: ProductType,
  unavailabilities: Collection<Unavailability>
  unit_price: number,
  updated_at: DateTime,
  warnings: ProductWarnings,
}

class ProductModel extends Model<IProduct> {
  _slug = 'product';

  attributesTypes: { [attr in keyof IProduct]?: (sourceAttr: any) => IProduct[attr] } = {
    created_at         : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at         : (d) => DateTime.fromISO(d),
    impressions_models : Collection.ofType(ProductImpressionsModel).make,
    inventory_resource : Model.make(InventoryResource),
    locations          : Collection.ofType(Location).make,
    category           : Model.make(ProductCategory),
    cover_picture      : Model.make(InventoryPicture),
    property           : Model.make(Property),
    format             : Model.make(Format),
    site_type          : Model.make(PropertyType),
    pictures           : Collection.ofType(InventoryPicture).make,
    pricelist          : Model.make(Pricelist),
    attachments        : Collection.ofType(ProductAttachment).make,
    loop_configurations: Collection.ofType(LoopConfiguration).make,
    unavailabilities   : Collection.ofType(Unavailability).make,
    opening_hours      : Collection.ofType(OpeningHours).make,
    restrictions       : Collection.ofType(InventoryResourceRestriction).make,
    screen_type        : Model.make(ScreenType),
  };

  basePath = '/v1/products';

  key: keyof IProduct = 'id';

  routesAttributes: { [attr in ModelPersistAction]: (keyof IProduct | string)[] } = {
    create: [],
    save  : [
      'is_sellable',
      'format_id_inherited',
      'format_id',
      'site_type_id_inherited',
      'site_type_id',
      'allowed_media_types_inherited',
      'allowed_media_types',
      'allows_audio_inherited',
      'allows_audio',
      'allows_motion_inherited',
      'allows_motion',
      'production_cost_inherited',
      'production_cost',
      'programmatic_price_inherited',
      'programmatic_price',
      'screen_type_id_inherited',
      'screen_type_id',
      'screen_size_in_inherited',
      'screen_size_in',
      'cover_picture_id',
      'primary_broadcast_location_id',
      'sale_types_slugs_inherited',
      'sale_types_slugs',
      'notes',
    ],
  };

}

export class Product extends ProductModel implements IProduct, InventoryResourceModel {
  allowed_media_types!: MediaType[];

  allowed_media_types_inherited!: boolean;

  allows_audio!: boolean | null;

  allows_audio_inherited!: boolean;

  allows_motion!: boolean | null;

  allows_motion_inherited!: boolean;

  attachments!: Collection<ProductAttachment>;

  category!: ProductCategory;

  category_id!: number;

  cover_picture!: InventoryPicture | null;

  cover_picture_id!: number | null;

  created_at!: DateTime;

  format!: Format;

  format_id!: number | null;

  format_id_inherited!: boolean;

  id!: number;

  impressions_models!: Collection<ProductImpressionsModel>;

  inventory_resource!: InventoryResource;

  inventory_resource_id!: number;

  is_sellable!: boolean;

  linked_product_id!: boolean;

  locations!: Collection<Location>;

  loop_configurations!: Collection<LoopConfiguration>;

  mockups_count!: number;

  name_en!: string;

  name_fr!: string;

  notes!: string;

  opening_hours!: Collection<OpeningHours>;

  pictures!: Collection<InventoryPicture>;

  pictures_count!: number;

  pricelist!: Pricelist;

  primary_broadcast_location_id!: number | null;

  production_cost!: number | null;

  production_cost_inherited!: boolean;

  programmatic_price!: number | null;

  programmatic_price_inherited!: boolean;

  property!: Property;

  property_id!: number;

  quantity!: number;

  restrictions!: Collection<InventoryResourceRestriction>;

  sale_types_slugs!: SaleTypeEnum[];

  sale_types_slugs_inherited!: boolean;

  screen_size_in!: number | null;

  screen_size_in_inherited!: boolean;

  screen_type!: ScreenType | null;

  screen_type_id!: number | null;

  screen_type_id_inherited!: boolean;

  site_type!: PropertyType | null;

  site_type_id!: number | null;

  site_type_id_inherited!: boolean;

  type!: ProductType;

  unavailabilities!: Collection<Unavailability>;

  unit_price!: number;

  updated_at!: DateTime;

  warnings!: ProductWarnings;

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }


  static localizedNameKey(): LocalizedKey<'name'> {
    return `name_${ i18n.language }` as LocalizedKey<'name'>;
  }

  localizedName() {
    return this[Product.localizedNameKey()];
  }

  getName() {
    return this.localizedName();
  }

  /**
   * A product is considered to be BUA if its only sale type is BUA
   * @return bool
   */
  isBUA() {
    return this.sale_types_slugs.length === 1 && this.sale_types_slugs[0] === SaleTypeEnum.BUA;
  }

  syncLocations(locations: number[]) {
    const path   = preparePathForSingleModelAction(this.basePath, '/locations');
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.put);

    return makeRequest<ILocation[]>(route, params, { locations })
      .then(({ data }) => {
        this.setAttributes({
          locations: data as Collection<Location>,
        });

        return data;
      });
  }
}

export class CampaignProduct extends Product {
  _slug = 'campaign-product';

  basePath = '/v2/campaigns/{campaign_id}/products';

  constructor(attributes = {}) {
    super();
    this.setAttributes(attributes);
  }
}

export class PublicProduct extends Product {
  basePath = '/public/v1/products';
}

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - PricelistPeriod.ts
 */

import { Model }               from 'library/Model';
import { DateTime }            from 'luxon';
import { ModelPersistAction }  from 'library/Model/types';
import { PricelistPeriodType } from './enums/PricelistPeriodType';
import { Pricelist }           from './Pricelist';
import { PricelistEntry }      from './PricelistEntry';
import Collection              from '../library/Collection';
import { DateString }          from '../library/utils/dateTime';
import { TFunction }           from 'i18next';

export interface IPricelistPeriod {
  id: number,
  pricelist_id: number,
  label: string,
  type: PricelistPeriodType,
  start: string | null,
  end: string | null,
  resolved_start_date: DateString | null,
  resolved_end_date: DateString | null,
  color: string,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  // Relations ----------

  pricelist: Pricelist,
  entries: Collection<PricelistEntry>
}

class PricelistPeriodModel extends Model<IPricelistPeriod> {
  _slug = 'pricelist-period';

  basePath = '/v2/pricelist-periods';

  key = 'id';

  attributesTypes: { [attr in keyof IPricelistPeriod]?: (sourceAttr: any) => IPricelistPeriod[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    pricelist : Model.make(Pricelist),
    entries   : Collection.ofType(PricelistEntry).make,
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'pricelist_id', 'label', 'type', 'start', 'end', 'color' ],
    save  : [ 'label', 'type', 'start', 'end', 'color' ],
  };
}

export class PricelistPeriod extends PricelistPeriodModel implements IPricelistPeriod {
  id!: number;

  pricelist_id!: number;

  label!: string;

  type!: PricelistPeriodType;

  start!: string | null;

  end!: string | null;

  resolved_start_date!: DateString | null;

  resolved_end_date!: DateString | null;

  color!: string;

  created_at!: DateTime<true>;

  updated_at!: DateTime<true>;

  deleted_at!: DateTime<true> | null;

  pricelist!: Pricelist;

  entries!: Collection<PricelistEntry>;


  constructor(attributes?: Partial<IPricelistPeriod>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  isPast() {
    return this.resolved_end_date !== null && this.resolved_end_date < DateTime.now().toISODate();
  }

  getLabel(t: TFunction) {
    if (this.label) {
      return this.label;
    }

    if (this.type === PricelistPeriodType.Always) {
      return t('pricelists:period.title.always');
    }

    if (this.type === PricelistPeriodType.FiscalMonths) {
      if (this.start === this.end) {
        return t('pricelists:period.title.fiscal-months-single',
          {
            month         : DateTime.fromObject({ month: Number(this.start?.split('-')[1]) }).monthLong,
            year          : this.start?.split('-')[0],
            resolved_start: this.resolved_start_date,
            resolved_end  : this.resolved_end_date,
          },
        );
      }

      if (this.start?.split('-')[0] === this.end?.split('-')[0]) {
        return t('pricelists:period.title.fiscal-months-same-year',
          {
            start         : DateTime.fromObject({ month: Number(this.start?.split('-')[1]) }).monthLong,
            end           : DateTime.fromObject({ month: Number(this.end?.split('-')[1]) }).monthLong,
            year          : this.start?.split('-')[0],
            resolved_start: this.resolved_start_date,
            resolved_end  : this.resolved_end_date,
          },
        );
      }

      return t('pricelists:period.title.fiscal-months',
        {
          start         : DateTime.fromObject({ month: Number(this.start?.split('-')[1]) }).monthLong,
          start_year    : this.start?.split('-')[0],
          end           : DateTime.fromObject({ month: Number(this.end?.split('-')[1]) }).monthLong,
          end_year      : this.end?.split('-')[0],
          resolved_start: this.resolved_start_date,
          resolved_end  : this.resolved_end_date,
        },
      );
    }

    return `${ this.resolved_start_date } - ${ this.resolved_end_date }`;
  }
}

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - PricelistEntry.ts
 */

import { Model }               from 'library/Model';
import { DateTime }            from 'luxon';
import { PricelistPeriod }     from './PricelistPeriod';
import { InventoryResource }   from './InventoryResource';
import Collection              from '../library/Collection';
import { PricelistEntryPrice } from './PricelistEntryPrice';

export interface IPricelistEntry {
  id: number,
  pricelist_period_id: number,
  inventory_resource_id: number,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  // Relations ------------

  period: PricelistPeriod,
  inventory_resource: InventoryResource,
  prices: Collection<PricelistEntryPrice>
}

class PricelistEntryModel extends Model<IPricelistEntry> {
  _slug = 'pricelist-entry';

  basePath = '/v2/pricelist-entries';

  key = 'id';

  attributesTypes: { [attr in keyof IPricelistEntry]?: (sourceAttr: any) => IPricelistEntry[attr] } = {
    created_at        : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at        : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at        : (d) => DateTime.fromISO(d, { zone: 'utc' }),
    period            : Model.make(PricelistPeriod),
    inventory_resource: Model.make(InventoryResource),
    prices            : Collection.ofType(PricelistEntryPrice).make,
  };

  routesAttributes = {
    create: {
      pricelist_period_id  : 'pricelist_period_id',
      inventory_resource_id: 'inventory_resource_id',
    },
    save  : {
      pricelist_period_id  : 'pricelist_period_id',
      inventory_resource_id: 'inventory_resource_id',
    },
  };
}

export class PricelistEntry extends PricelistEntryModel implements IPricelistEntry {
  id!: number;

  pricelist_period_id!: number;

  inventory_resource_id!: number;

  created_at!: DateTime<true>;

  updated_at!: DateTime<true>;

  deleted_at!: DateTime<true> | null;

  period!: PricelistPeriod;

  inventory_resource!: InventoryResource;

  prices!: Collection<PricelistEntryPrice>;

  constructor(attributes?: Partial<IPricelistEntry>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  async onCreated(response: Partial<IPricelistEntry>): Promise<this> {
    await super.onCreated(response);
    await (new PricelistPeriod({ id: this.pricelist_period_id })).invalidate();
    return this;
  }

  async onSaved(response: Partial<IPricelistEntry>): Promise<this> {
    await super.onSaved(response);
    await (new PricelistPeriod({ id: this.pricelist_period_id })).invalidate();
    return this;
  }

  async onDeleted(response: Partial<IPricelistEntry>): Promise<this> {
    await super.onDeleted(response);
    await (new PricelistPeriod({ id: this.pricelist_period_id })).invalidate();
    return this;
  }
}

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Model.ts
 */

import { CancelTokenHolder, HTTPMethod, QueryParameters }         from '../Request';
import {
  Constructor,
  ModelAPIAllResponse,
  ModelAttributes,
  ModelEvents,
  ModelObserver,
  ModelObserverFn,
  ModelPersistAction,
  ModelProperties,
  RouteAttributesDescriptor,
}                                                                 from './types';
import API, { APICreateOptions }                                  from './API';
import { queryClient }                                            from './QueryClient';
import * as utils                                                 from './utils';
import { preparePathForSingleModelAction, preparePathParameters } from './utils';
import { makeRoute }                                              from '../modelsUtils';
import { makeRequest }                                            from '../Request/Request';

export abstract class Model<Attributes extends ModelAttributes> implements ModelProperties<Attributes>,
                                                                           ModelEvents<Attributes>,
                                                                           ModelObserver<Attributes> {
  /**
   * String representing the model, must be unique for every model type
   */
  abstract _slug: string;

  /**
   * Endpoint of the model
   */
  abstract basePath: string;

  /**
   * List of routes, with placeholders, that should be invalidated from the cache when a model is created/saved/deleted
   */
  invalidateRoutes: string[] = [];

  /**
   * Tell if the model's index endpoint is paginated; This has impact on fetching and invalidation behaviours
   */
  isPaginated: boolean = false;

  /**
   * Name of the property that serve as the key for the model. Most of the time 'id'
   */
  abstract key: string;

  /**
   * Mapper for the different attributes of the model that require to be transformed when being received
   */
  abstract attributesTypes: { [attr in keyof Attributes]?: (sourceAttr: any) => Attributes[attr]; };

  /**
   * List of the attributes for each action that should make the body of the request
   */
  abstract routesAttributes: { [attr in ModelPersistAction]: RouteAttributesDescriptor<Attributes> };

  /**
   * Stores the list of public relations loaded with the model: the `with` query parameter, to be reused when saving
   */
  _loadedRelations: string[] = [];

  constructor() {
    // Hide internal properties to prevent them from polluting objects created by spreading a model
    Object.defineProperty(this, 'key', { enumerable: false });
    Object.defineProperty(this, 'attributesTypes', { enumerable: false });
    Object.defineProperty(this, 'routesAttributes', { enumerable: false });
    Object.defineProperty(this, '_slug', { enumerable: false });
    Object.defineProperty(this, '_observers', { enumerable: false });
    Object.defineProperty(this, '_loadedRelations', { enumerable: false });
    Object.defineProperty(this, 'basePath', { enumerable: false });
    Object.defineProperty(this, 'invalidateRoutes', { enumerable: false });
  }

  static make<T extends ModelAttributes, U extends Model<any>>(ModelType: Constructor<U>) {
    return (attr: Partial<T>) => {
      const model = new ModelType();
      model.setAttributes(attr ?? {});

      return model;
    };
  }

  /**
   * Pull a single LegacyModel from the API
   *
   * @param key
   * @param queryParameters
   * @param cancelToken
   */
  static async get<Attributes extends ModelAttributes>(
    key: string | number,
    queryParameters: QueryParameters = {},
    cancelToken: CancelTokenHolder   = {},
  ): Promise<any> {
    // @ts-ignore
    return API.get(this, key, queryParameters, cancelToken);
  }

  clone() {
    // @ts-ignore
    return (new this.constructor({})).setAttributes(this);
  }

  /**
   *
   * @param current
   * @param all Use `Model.invalidateAll` instead
   */
  async invalidate(current: boolean = true, all: boolean = false): Promise<void[]> {
    const promises = [];

    // Invalidate `get` queries for this model and key
    if (current) {
      promises.push(
        queryClient.invalidateQueries({
          queryKey : [ this._slug ],
          predicate: (query) => {
            if (!Array.isArray(query.queryKey)) {
              return false;
            }

            if (query.queryKey[0] !== this._slug) {
              return false;
            }

            return query.queryKey[1] === this.getKey();
          },
        }),
      );
    }

    if (all) {
      promises.push(this.invalidateAll());
    }

    return await Promise.all(promises);
  }

  /**
   * Invalidate all requests for multiple models.
   * Specific `get` requests will not be invalidated
   *
   * @param invalidateSingleRequests Also delete single model queries
   */
  async invalidateAll(invalidateSingleRequests = false) {
    return queryClient.invalidateQueries({
      queryKey : [ this._slug ],
      predicate: (query) => {
        if (!Array.isArray(query.queryKey)) {
          return false;
        }

        if (query.queryKey[0] !== this._slug) {
          return false;
        }

        if (invalidateSingleRequests) {
          return true;
        }

        if (query.queryKey[1] === 'all') {
          return true;
        }

        return query.queryKey[1] === 'ids' && Array.isArray(query.queryKey[2]) && query.queryKey[2].includes(this.getKey());
      },
    });
  }

  setAttributes(attributes: Partial<Attributes> = {}) {
    for (const [ attr, value ] of Object.entries(attributes)) {
      // If the attribute value is null or undefined, we just apply it untouched
      if (value === undefined || value === null) {
        // @ts-ignore
        this[attr] = value;
        continue;
      }

      // If the attribute is set, and a mapping function is set, apply the transformation,
      if (this.attributesTypes.hasOwnProperty(attr)) {
        try {
          // @ts-ignore
          this[attr] = this.attributesTypes[attr](value);
        } catch (e) {
          console.error('Error when formatting attribute', attr, value, e);
          // @ts-ignore
          this[attr] = null;
        }
        continue;
      }

      // @ts-ignore
      this[attr] = value;
    }

    return this;
  };

  getKey() {
    // @ts-ignore
    return this[this.key];
  };

  /**
   * Tell if the current model has been soft deletedeleted.
   * Internally, this method checks if the `deleted_at` property exist and is set
   */
  isTrashed(): boolean {
    if ('deleted_at' in this) {
      return this['deleted_at'] !== null && this['deleted_at'] !== undefined;
    }

    return false;
  }

  async create<T extends Model<Attributes>>(options: Omit<APICreateOptions<Attributes, T>, 'model'> = {}): Promise<this> {
    return API.create<Attributes, typeof this>({ model: this, ...options })
              .then(async model => {
                // noinspection SuspiciousTypeOfGuard
                if (model instanceof Model) {
                  await this.invalidate();
                  this.invalidateAll();
                }

                return model;
              });
  }

  async save(
    additionalParameters: QueryParameters = {},
    signal?: AbortSignal,
    bodyExtension: Record<string, any>    = {},
  ): Promise<this> {
    return API.save<Attributes, typeof this>(this, additionalParameters, signal, bodyExtension)
              .then(async (model) => {
                queryClient.setQueriesData({ queryKey: [ model._slug, model.getKey(), { with: this._loadedRelations } ] },
                  () => model,
                );

                this.invalidateAll();

                return model;
              });
  }

  reload() {
    const path   = preparePathForSingleModelAction(this.basePath);
    const params = preparePathParameters(path, this);
    const route  = makeRoute(path, HTTPMethod.get);

    return makeRequest<Attributes>(route, params, { with: this._loadedRelations })
      .then(({ data }) => {
        return this.onReloaded(data);
      })
      .catch(utils.handleBadRequest);
  }

  async delete(additionalParameters: QueryParameters = {}): Promise<this> {
    return API.destroy(this, additionalParameters).then(model => {
      // queryClient.invalidateQueries([ model._slug, 'all' ]);

      return model;
    });
  }

  /**
   * Event Callback.
   * This method will be called after creating a model through the `API.create` or `Model.create` method.
   *
   * By default, it applies the response's data to the model. If overridden, you have to take care of that yourself or call this method.
   * @param response
   */
  async onCreated(response: Partial<Attributes>): Promise<this> {
    this.setAttributes(response);
    this.notifyObservers();
    return this;
  }

  /**
   * Event Callback.
   * This method will be called after saving a model through the `API.save` or `Model.save` method.
   *
   * By default, it applies the response's data to the model. If overridden, you have to take care of that yourself
   * or call this method.
   * @param response
   */
  async onSaved(response: Partial<Attributes>): Promise<this> {
    this.setAttributes(response);
    this.notifyObservers();
    return this;
  }

  /**
   * Event Callback.
   * This method will be called after refreshing a model through the `Model.reload` method.
   *
   * By default, it applies the response's data to the model. If overridden, you have to take care of that yourself
   * or call this method.
   *
   * This method is not called if the reload is done by the queryClient
   * @param response
   */
  async onReloaded(response: Partial<Attributes>): Promise<this> {
    this.setAttributes(response);

    queryClient.setQueriesData({ queryKey: [ this._slug, this.getKey(), { with: this._loadedRelations } ] }, () => this);

    this.notifyObservers();
    return this;
  }

  /**
   * Event Callback.
   * This method will be called after deleting a model through the `API.destroy` or `Model.delete` method.
   * By default, this method doesn't do anything.
   * @param response
   */
  async onDeleted(response: Partial<Attributes>): Promise<this> {
    this.setAttributes(response);
    this.notifyObservers();

    if (this.isPaginated) {
      // Do not wait on `invalidateAll()`
      this.invalidateAll();
      return this;
    }

    queryClient.setQueriesData({ queryKey: [ this._slug, 'all' ] }, (entry?: ModelAPIAllResponse<any>) => {
      if (entry === undefined) {
        return entry;
      }

      return { models: entry.models?.filter(model => model.getKey() !== this.getKey()) ?? null, headers: entry.headers };
    });

    return this;
  }

  protected _observers: Record<string, ModelObserverFn<Attributes, Model<Attributes> & Attributes>> = {};

  addObserver(observerId: string, callback: ModelObserverFn<Attributes, Model<Attributes> & Attributes>) {
    this._observers[observerId] = callback;
  }

  removeObserver(observerId: string) {
    delete this._observers[observerId];
  }

  protected notifyObservers() {
    Object.values(this._observers)
      //@ts-ignore
          .forEach(observerCallback => observerCallback(this));
  }
}

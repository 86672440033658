/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - Pricelist.ts
 */

import { Model }                       from 'library/Model';
import { DateTime }                    from 'luxon';
import { ModelPersistAction }         from 'library/Model/types';
import Collection                      from 'library/Collection';
import { PricelistProperty, Property } from 'models';
import { makeRequest }                from 'library/Request/Request';
import { makeRoute }                  from 'library/modelsUtils';
import { HTTPMethod }                 from 'library/Request';
import { PricelistPeriod }            from './PricelistPeriod';

export interface IPricelist {
  id: number,
  name: string,
  description: string | null,

  created_at: DateTime,
  updated_at: DateTime,

  // Relations ----------

  periods: Collection<PricelistPeriod>;
  properties: Collection<Property>;
}

class PricelistModel extends Model<IPricelist> {
  _slug = 'pricelist';

  basePath = '/v2/pricelists';

  key = 'id';

  attributesTypes: { [attr in keyof IPricelist]?: (sourceAttr: any) => IPricelist[attr] } = {
    periods   : Collection.ofType(PricelistPeriod).make,
    properties: Collection.ofType(Property).make,
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
  };

  routesAttributes: { [attr in ModelPersistAction]: string[] } = {
    create: [ 'name', 'description' ],
    save  : [ 'name', 'description' ],
  };
}

export class Pricelist extends PricelistModel implements IPricelist {
  created_at!: DateTime;

  description!: string | null;

  id!: number;

  name!: string;

  updated_at!: DateTime;

  periods!: Collection<PricelistPeriod>;

  properties!: Collection<Property>;

  constructor(attributes?: Partial<IPricelist>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  async syncProperties(propertiesIds: number[]) {
    await makeRequest(makeRoute((new PricelistProperty()).basePath, HTTPMethod.put), {
      pricelist_id: this.getKey(),
    }, {
      ids: propertiesIds,
    });
    await this.invalidate();
    await (new Property()).invalidateAll();
    propertiesIds.forEach(id => {
      (new Property({ actor_id: id })).invalidate();
    });
  }
}

/*
 * Copyright 2025 (c) Neo-OOH - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Valentin Dufois <valentin@webisoft.com>
 *
 * @neo/connect - PricelistEntryPrice.ts
 */

import { Model }          from 'library/Model';
import { DateTime }       from 'luxon';
import { PriceType }      from './enums/PriceType';
import { SaleType }       from './SaleType';
import { PricelistEntry } from './PricelistEntry';
import { TFunction }      from 'i18next';
import { formatPrice }    from '../library/utils/prices';

export interface IPricelistEntryPrice {
  id: number,
  pricelist_entry_id: number,
  sale_type_id: number,
  pricing: PriceType,
  value: number,

  created_at: DateTime,
  updated_at: DateTime,
  deleted_at: DateTime | null,

  // Relations ----------

  entry: PricelistEntry,
  sale_type: SaleType,
}

class PricelistEntryPriceModel extends Model<IPricelistEntryPrice> {
  _slug = 'pricelist-entry';

  basePath = '/v2/pricelist-entry-prices';

  key = 'id';

  attributesTypes: { [attr in keyof IPricelistEntryPrice]?: (sourceAttr: any) => IPricelistEntryPrice[attr] } = {
    created_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    updated_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    deleted_at: (d) => DateTime.fromISO(d, { zone: 'utc' }),
    entry     : Model.make(PricelistEntry),
    sale_type : Model.make(SaleType),
  };

  routesAttributes = {
    create: [],
    save  : [],
  };
}

export class PricelistEntryPrice extends PricelistEntryPriceModel implements IPricelistEntryPrice {
  id!: number;

  pricelist_entry_id!: number;

  sale_type_id!: number;

  pricing!: PriceType;

  value!: number;

  created_at!: DateTime<true>;

  updated_at!: DateTime<true>;

  deleted_at!: DateTime<true> | null;

  entry!: PricelistEntry;

  sale_type!: SaleType;

  constructor(attributes?: Partial<IPricelistEntryPrice>) {
    super();
    this.setAttributes(attributes ?? {});
  }

  getFormattedLabel(t: TFunction) {
    return formatPrice(this, t);
  }
}
